<template>
  <div>
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">กรุณารอสักครู่...</p>
        </div>
      </template>
      <button class="btn btn-gradient-primary mb-1" style="width: 120px"
      @click="SubmitData()">
        {{ this.$t("saveData") }}
      </button>
      <button class="btn btn-gradient-danger mb-1 mx-1" style="width: 120px"
      :disabled="!items.length"
      @click="ClearData()"
      >
        ล้างข้อมูล
      </button>
      <div class="card">
        <div
          class="d-lg-flex justify-content-between align-items-center px-2 mt-2"
        >
          <h3 class="font-weight-bolder">ปิดงานโกดัง</h3>
          <div class="d-flex">
            <!-- <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div
                  class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25"
                >
                  <input
                    v-model="search_val"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                  />
                  <div class="anan-input__suffix">
                    <button
                      type="button"
                      class="anan-button anan-button--normal anan-button--primary"
                    >
                      <i class="fal fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="mt-4 mt-lg-0">
          <b-row>
            <b-col md="12">
              <b-row class="mx-auto p-2">
                <div class="d-flex align-items-center flex-wrap flex-grow-1">
                  <b-form-input
                    v-model="inputText"
                    placeholder="กรอกบาร์โค้ด"
                    class="col-lg-3 col-sm-2 text-uppercase"
                    @keyup.enter="addBarcode()"
                  ></b-form-input>
                  <b-button variant="primary" class="ml-1"
                    @click="addBarcode()"
                  >
                    เพิ่มบาร์โค้ด
                  </b-button>
                  <b-form-group class="ml-2 mt-1">
                    <b-form-radio-group
                      v-model="selectedOption"
                      name="radio-options"
                    >
                      <b-form-radio value="1">หาของ</b-form-radio>
                      <b-form-radio value="2">แพ็คของ</b-form-radio>
                      <b-form-radio value="3">เช็คของ</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>

                <b-table
                  :items="items"
                  :fields="fields"
                  class="mx-auto"
                  striped
                  responsive=""
                  show-empty
                >
                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>
                  <template #cell(status)="data">
                    <div
                      class="d-flex align-items-center justify-content-center mt-1"
                      >
                      <b-alert
                        variant="info"
                        class="text-center"
                        show
                        style="width: 120px; font-size: 11px"
                        v-if="data.item.search_uid"
                      >
                        <feather-icon icon="SearchIcon" /> หาของ,
                        {{ data.item.search_name }}
                      </b-alert>
                      <b-alert
                        variant="success"
                        class="text-center mx-1"
                        show
                        style="width: 120px; font-size: 11px"
                        v-if="data.item.pack_uid"
                      >
                        <feather-icon icon="BoxIcon" /> แพ็คของ,
                        {{ data.item.pack_name }}
                      </b-alert>
                        <b-alert
                        variant="warning"
                        class="text-center mx-1"
                        show
                        style="width: 120px; font-size: 11px"
                        v-if="data.item.check_uid"
                      >
                        <feather-icon icon="BoxIcon" /> เช็คของ,
                        {{ data.item.check_name }}
                      </b-alert>
                    </div>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="deleteItem(data.index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </template>
                </b-table>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
  
  <script>
import {
  BCard,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
  BTable,
  BFormRadio,
  BFormRadioGroup,
  BIcon,
  BCol,
  BRow,
  BAlert,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BFormRadio,
    BFormRadioGroup,
    BIcon,
    BCol,
    BRow,
    BAlert,
    BOverlay,
  },
  data() {
    return {
      search_val: null,
      showOver: false,
      inputText: "",
      selectedOption: "1",
      items: [],
      fields: [
        {
          key: "itemnumber_text",
          label: "หมายเลขรายการ",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "username",
          label: "รหัสลูกค้า",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "postnumber",
          label: "จำนวนรหัสพัสดุ",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status",
          label: "ปิดงานไปแล้ว",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      items: [],
    };
  },
  methods: {},
  methods: {
    addBarcode() {
      this.inputText = this.checkInput(this.inputText)
      const params = {
        itemnumber_text: this.inputText.toUpperCase()
      }
      this.$http.get('/SearchClosework/payment', { params })
          .then((response) => {
            if (response.data.status) {
              const checkitem = this.items.find(ele => ele._id == response.data.data._id)
              if (checkitem) {
                return this.SwalError('ข้อมูลซ้ำ')
              }
              this.items.unshift(response.data.data)
              this.inputText = null
            }
          })
          .catch((err) => {
            console.log(err)
            this.SwalError(err.response.data.data)
          }); 
    },
    SubmitData() {
      this.showOver = true
      if (!this.items.length) {
        this.showOver = false
          return this.$swal({
            title: '<h3 style="color: #141414">ไม่มีข้อมูล</h3>',
            icon: 'warning',
            text: 'กรุณาเพิ่มข้อมูล',
            confirmButtonText: 'ปิด',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
          })
        } else if (!this.selectedOption) {
          this.showOver = false
        return this.$swal({
            // title: '<h3 style="color: #141414">ไม่มีข้อมูล</h3>',
            icon: 'warning',
            text: 'กรุณาเลือก หาของ แพ็คของ หรือ เช็คของ',
            confirmButtonText: 'ปิด',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
          })

        } else { 
          if (this.selectedOption === '1') {
            const checkitem = this.items.filter(ele => ele.search_uid)
            if (checkitem.length) {
              const itemsWithSearchName = checkitem.map(item => item.itemnumber_text).join(', ');
              this.showOver = false
              return this.$swal({
              // title: '<h3 style="color: #141414">ไม่มีข้อมูล</h3>',
              icon: 'warning',
              text: `บันทึกไม่ได้ เนื่องจาก ${itemsWithSearchName} หาของแล้ว`,
              confirmButtonText: 'ปิด',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              })
            }
          }
          if (this.selectedOption === '2') {
            const checkitem = this.items.filter(ele => ele.pack_uid)
            if (checkitem.length) {
              const itemsWithSearchName = checkitem.map(item => item.itemnumber_text).join(', ');
              this.showOver = false
              return this.$swal({
              // title: '<h3 style="color: #141414">ไม่มีข้อมูล</h3>',
              icon: 'warning',
              text: `บันทึกไม่ได้ เนื่องจาก ${itemsWithSearchName} แพ็คของแล้ว`,
              confirmButtonText: 'ปิด',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              })
            }
          }
          if (this.selectedOption === '3') {
            const checkitem = this.items.filter(ele => ele.check_uid)
            if (checkitem.length) {
              const itemsWithSearchName = checkitem.map(item => item.itemnumber_text).join(', ');
              this.showOver = false
              return this.$swal({
              // title: '<h3 style="color: #141414">ไม่มีข้อมูล</h3>',
              icon: 'warning',
              text: `บันทึกไม่ได้ เนื่องจาก ${itemsWithSearchName} เช็คของแล้ว`,
              confirmButtonText: 'ปิด',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              })
            }
          }
          const obj = {
            type: this.selectedOption,
            ID: this.items.map(item => item._id),
          }
          this.$http.post('/Closework/payment', obj)
          .then((response) => {
              if (response.data.status) {
                this.$swal({
                // title: '<h3 style="color: #141414">ไม่มีข้อมูล</h3>',
                icon: 'success',
                text: `${response.data.data}`,
                confirmButtonText: 'ปิด',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                })
                  this.items = []
                  this.selectedOption = '1'
                  this.inputText = null
                  this.showOver = false
              }
          }).catch((err) => {
            console.log(err)
            this.$swal({
              // title: '<h3 style="color: #141414">ไม่มีข้อมูล</h3>',
              icon: 'warning',
              text: `${err.response.data.data}`,
              confirmButtonText: 'ปิด',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              })
              this.showOver = false
          });
          
        }
    },
    ClearData() {
        this.$swal({
        title: '<h3 style="color: #141414">ล้างข้อมูล</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: 'คุณต้องล้างข้อมูลหรือไม่ ?',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.items = []
        }
      }
      )
    },
    checkInput(data) {
      if (/\s/.test(data) || /\t/.test(data)) {
       return data.replace(/\s/g, '').replace(/\t/g, '')
      }
      return data
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      })
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>
  
  <style>
.radio-btn {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.radio-btn.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
</style>
  